<template>
  <b-list-group-item
    class="d-flex justify-content-between align-items-center"
    @action="logout"
  >
    {{ $t('therms.logout') }}
    <b-list-group-item-button
      disable
      color="primary"
    >
      <i class="fa-solid fa-right-from-bracket" />
    </b-list-group-item-button>
  </b-list-group-item>
</template>

<script>
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import ListGroupItemButton from '@core/components/list-group/ListGroupItemButton.vue'
import { Dialog } from '@capacitor/dialog'
import { NAME_INIT } from '@/router/routes/auth'

export default {
  components: {
    'b-list-group-item': ListGroupItem,
    'b-list-group-item-button': ListGroupItemButton,
  },
  data() {
    return {}
  },
  computed: {
  },
  methods: {
    async logout() {
      const { value } = await Dialog.confirm({
        title: this.$t('therms.logout').toString(),
        message: this.$t('actions.logout.message').toString(),
        okButtonTitle: this.$t('therms.confirm').toString(),
        cancelButtonTitle: this.$t('therms.cancel').toString(),
      })

      if (value && await this.$store.dispatch('auth/logout')) {
        await this.$router.replace({
          name: NAME_INIT,
        })

        this.$store.commit('farmers/clear')
        this.$store.commit('campaigns/clear')
        this.$store.commit('harvestHistory/clear')
        this.$store.commit('harvestNow/clear')
      }
    },
  },
}
</script>
