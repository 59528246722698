<template>
  <b-list-group-item
    :active="false"
    class="d-flex justify-content-between align-items-center"
  >
    {{ $t('therms.display-nationality') }}
    <b-form-checkbox
      v-model="active"
      style="margin-right: -0.7rem;"
      class="custom-control-primary"
      name="check-button"
      switch
    />
  </b-list-group-item>
</template>

<script>
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import { BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    'b-list-group-item': ListGroupItem,
    BFormCheckbox,
  },
  data() {
    return {
      active: false,
    }
  },
  computed: {},
  watch: {
    active() {
      this.$store.commit('settings/setDisplayLanguage', this.active)
    },
  },
  mounted() {
    this.active = this.$store.getters['settings/getDisplayLanguage']
  },
  methods: {},
}
</script>
